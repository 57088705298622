html {
    background-color: #fff;
    scroll-behavior: smooth;
  }
  body {
    background-color: #fff;
    font-family: Inter, sans-serif;
    font-size: 16px;
  }
  .game-modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7); 
      z-index: 1000;   
      display: none;
  }
  .game-modal.open {
      display: block;
  }
  .game-modal .close-modal {
      position: absolute;
      top: 5px;
      right: 5px;
      border-radius: 5px;
      width: 30px;
      height: 30px;
  }
  .game-modal .close-modal img {
      width: 100%;
  }
  .game-modal .modal-inner {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1020px;
      height: 600px;
      padding: 20px;
      overflow: scroll;
      background-color: white;
  }
  .game-modal .modal-inner iframe {
      height: 100%;
  }
  .cookie-banner {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #333;
      color: #fff;
      padding: 15px;
      text-align: center;
      display: none;
      align-items: center;
      justify-content: center;
      z-index: 200;
  }
  .cookie-banner p {
      margin: 0;
  }
  .cookie-banner button {
      background-color: #0ed085;
      color: #333;
      border: none;
      padding: 10px 30px;
      cursor: pointer;
      margin-left: 15px;
  }
  
  .cookie-banner button:hover {
      background-color: #0fe090;
  }
  
  .age-check-popup {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000000d9;
      z-index: 200;
  }
  .age-check-popup.hidden {
      display: none;
  }
  .age-check-popup .popup-inner-wrapper {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      border-radius: 4px;
      padding: 16px;
      max-width: 500px;
  }
  .age-check-popup .popup-inner-wrapper img {
      width: 48px;
      height: 48px;
      margin: 0 auto 30px;
      display: block;
  }
  .age-check-popup .popup-inner-wrapper h3 {
      border-bottom: 1px solid black;
      padding-bottom: 10px;
      font-size: 24px;
      font-weight: 700;
  }
  .age-check-popup .popup-inner-wrapper p {
      margin-top: 40px;
      border-bottom: 1px solid black;
      padding-bottom: 30px;
  }
  .age-check-popup .popup-inner-wrapper p span {
      color: red;
  }
  .age-check-popup .popup-inner-wrapper button {
      width: 400px;
      border: none;
      margin: 0 auto;
      display: block;
      background-color: #198754;
      border-radius: 4px;
      color: white;
      padding: 10px;
  }
  .text {
      color: #111111;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 25px;
      line-height: 35px;
      font-weight: 400;
      font-family: Inter;
      margin-top: 0;
      margin-bottom: 0;
  }
  .text-md {
      font-size: 22px;
      line-height: 30px;
  }
  header .container {
      padding-top: 20px;
      padding-bottom: 20px;
  }
  .container .logo-wrapper {
      max-width: 300px;
  }
  .container .logo-wrapper > a:first-child {
      max-width: 60px;
      display: block;
      margin-right: 25px;
  }
  .container .logo-wrapper > a:first-child img {
      width: 100%;
  }
  .container .main-logo-text .game-name {
      color: #111111;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 19px;
      line-height: 29px;
      font-weight: 600;
      font-family: Inter;
  }
  .container .main-logo-text span {
      color: #111111;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 17px;
      line-height: 24px;
      font-weight: 400;
      font-family: Inter;
  }
  .container .navigation .nav-list {
      padding-left: 0;
      margin: 0;
      list-style: none;
  }
  .container .navigation svg {
      display: none;
  }
  .container .navigation .nav-list li + li {
      margin-left: 20px;
  }
  .container .navigation .nav-list li a {
      color: #111111;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 17px;
      line-height: 20px;
      font-weight: 500;
      font-family: Inter;
      margin: 0;
  }
  .hero {
      background-image: url(../public/hero-bg.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
  }
  .hero::after {
      content: "";
      background-color: rgba(255, 255, 255, 0.8);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
  }
  .hero .container {
      z-index: 2;
      position: relative;
      padding-top: 50px;
      padding-bottom: 50px;
  }
  .hero .container h1 {
      color: #111111;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 40px;
      line-height: 48px;
      font-weight: 700;
      font-family: Cardo;
      margin: 0;
      margin-bottom: 20px;
  }
  .hero .container .inner-wrapper .left-col {
      width: 720px;
  }
  .hero .container .inner-wrapper .left-col img {
      width: 100%;
  }
  .hero .container .inner-wrapper .right-col {
      width: calc(100% - 720px - 30px);
  }
  
  .second {
      background-image: url(../public/hero-bg.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
  }
  
  .second::after {
      content: "";
      background-color: rgba(255, 255, 255, 0.8);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
  }
  
  .second .container {
      position: relative;
      z-index: 2;
      padding-top: 50px;
      padding-bottom: 50px;
  }
  .second .container .inner-wrapper .left-col {
      width: calc(100% - 30px - 840px);
  }
  .second .container .inner-wrapper .right-col {
      width: 840px;
  }
  .second .container .inner-wrapper .right-col img {
      width: 100%;
  }
  
  .third {
      background-image: url(../public/hero-bg.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
  }
  
  .third::after {
      content: "";
      background-color: rgba(255, 255, 255, 0.8);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
  }
  .third .container {
      position: relative;
      z-index: 2;
      padding-top: 50px;
      padding-bottom: 50px;
  }
  .third .container .inner-wrapper .left-col {
      width: 475px;
  }
  .third .container .inner-wrapper .left-col img {
      width: 100%;
  }
  .third .container .inner-wrapper .right-col {
      width: calc(100% - 475px - 30px);
      padding-right: 50px;
      padding-left: 50px;
  }
  .fourth {
      background-image: url(../public/hero-bg.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
  }
  .fourth .container {
      padding-top: 60px;
      padding-bottom: 90px;
  }
  .fourth .container img {
      border-radius: 65px;
      overflow: hidden;
      max-width: 700px;
      margin: 0 auto 20px;
  }
  .fourth .container button {
      display: block;
      max-width: 700px;
      margin: 0 auto;
      width: 100%;
      border: 8px solid #111111;
      border-radius: 54px;
      background-color: #DD38DB;
      font-size: 40px;
      line-height: 62px;
      font-weight: 700;
      font-family: Inter;
      color: white;
  }
  
  footer .top-row .container {
      padding-top: 15px;
  }
  footer .top-row .container .footer-text {
      font-size: 22px;
      line-height: 24px;
      font-family: Cardo;
  }
  
  .contact-us {
      background-image: url(../public/hero-bg.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
  }
  .contact-us .container {
      padding-top: 100px;
      padding-bottom: 100px;
  }
  .contact-us .container .inner-wrapper .left-col {
      width: calc(100% - 100px - 555px);
  }
  .contact-us .container .inner-wrapper .left-col .contact-title {
      color: white;
      font-size: 52px;
      line-height: 62px;
      font-weight: 400;
      font-family: Cardo;
      margin: 0;
      margin-bottom: 20px;
  }
  .contact-us .container .inner-wrapper .left-col .contact-text {
      color: white;
      font-size: 30px;
      line-height: 45px;
      font-weight: 700;
      font-family: Inter;
      margin: 0;
  
  }
  .contact-us .container .inner-wrapper .right-col {
      width: 555px;
  }
  .contact-us .container .inner-wrapper .right-col form {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
  }
  .contact-us .container .inner-wrapper .right-col form .input-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  }
  .contact-us .container .inner-wrapper .right-col form .input-wrapper label {
      margin-bottom: 5px;
      margin-top: 0;
      color: #111111;
      font-size: 17px;
      line-height: 26px;
      font-weight: 700;
      font-family: Inter;
      margin: 0;
  }
  .contact-us .container .inner-wrapper .right-col form .input-wrapper label span {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      font-family: Inter;
  }
  .contact-us .container .inner-wrapper .right-col form .input-wrapper input,
  .contact-us .container .inner-wrapper .right-col form .input-wrapper textarea {
      width: 100%;
      padding: 16px;
  }
  .contact-us .container .inner-wrapper .right-col form .input-wrapper textarea {
      resize: none;
      height: 200px;
  }
  .contact-us .container .inner-wrapper .right-col form button {
      background-color: black;
      color: white;
      border-radius: 4px;
      margin-top: 20px;
      padding: 9px 16px;
  }
  .contact-us .container .inner-wrapper .right-col form .input-wrapper + .input-wrapper {
      margin-top: 25px;
  }
  .close-nav {
      display: none;
  }
  @media only screen and (max-width: 1000px) {
      .container .navigation svg {
          display: block !important;
      }
      .container .navigation .nav-list {
          display: none !important;
          position: absolute;
          top: 0;
          right: 0;
          flex-direction: column;
          background: white;
          width: 100%;
          z-index: 500;
          align-items: flex-end !important;
          padding: 60px 20px 40px;
          justify-content: flex-start !important;
      }
      .container .navigation .nav-list.open {
          display: flex !important;
      }
      .container .navigation .nav-list li + li {
          margin-left: 0;
          margin-top: 20px;
      }
      .close-nav {
          display: none;
      }
      .close-nav.open {
          display: block;
          position: absolute;
          z-index: 2001;
          top: 20px;
          right: 10px;
      }
      .hero .inner-wrapper {
          flex-direction: column;
      }
      .hero .container .inner-wrapper .left-col {
          width: 100%;
          margin-bottom: 20px;
      }
      .hero .container .inner-wrapper .right-col {
          width: 100%;
          text-align: center;
      }
      .hero .container {
          padding-top: 20px;
          padding-bottom: 20px;
      }
      .hero .container h1 {
          font-size: 30px;
          line-height: 36px;
      }
      .text {
          font-size: 17px;
          line-height: 27px;
      }
      .second .container  {
          padding-top: 20px;
          padding-bottom: 20px;
      }
      .second .container .inner-wrapper {
          flex-direction: column;
          text-align: center;
      }
      .second .container .inner-wrapper .left-col {
          width: 100%;
          margin-bottom: 20px;
      }
      .second .container .inner-wrapper .right-col {
          width: 100%;
      }
      .third .container  {
          padding-top: 20px;
          padding-bottom: 20px;
      }
      .third .container .inner-wrapper {
          flex-direction: column;
          text-align: center;
      }
      .third .container .inner-wrapper .left-col {
          width: 100%;
          margin-bottom: 20px;
      }
      .third .container .inner-wrapper .right-col {
          width: 100%;
      }
      .fourth .container img {
          width: 100%;
      }
      footer .top-row .container .footer-text {
          font-size: 15px;
          line-height: 17px;
      }
      .game-modal .modal-inner {
          width: calc(100% - 20px);
          height: 260px;
      }
      .game-modal .modal-inner iframe {
          height: 100%;
      }
      footer .navigation {
          display: none;
      }
      .contact-us .container {
          padding-top: 40px;
          padding-bottom: 40px;
      }
      .contact-us .container .inner-wrapper {
          flex-direction: column;
      }
      .contact-us .container .inner-wrapper .left-col {
          width: 100%;
          margin-bottom: 20px;
      }
      .contact-us .container .inner-wrapper .right-col {
          width: 100%;
      }
      .contact-us .container .inner-wrapper .left-col .contact-title {
          font-size: 40px;
          line-height: 44px;
      }
      .contact-us .container .inner-wrapper .left-col .contact-text {
          font-size: 23px;
          line-height: 27px;
      }
  }
  
